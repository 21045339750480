<template>
  <c-flex
    margin-top="16px"
    flex-direction="column"
    border="1px solid #C4C4C4"
    :padding="['12px 16px', '16px']"
    border-radius="8px"
  >
    <c-flex
      align-items="center"
      gap="8px"
    >
      <c-text
        font-size="14px"
        color="neutral.black"
      >
        {{ type === 'adult' ? 'Indeks Masa Tubuh' : 'Z-Score Status Gizi' }}
      </c-text>
      <c-popover
        v-if="popoverText !== ''"
        :use-portal="false"
        trigger="click"
        placement="bottom"
      >
        <c-popover-trigger>
          <c-box
            min-width="20px"
            min-height="20px"
            width="20px"
            height="20px"
            cursor="pointer"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-circle-info.svg')"
              stroke="#555555"
              width="100%"
              height="100%"
            />
          </c-box>
        </c-popover-trigger>
        <c-popover-content
          border="0"
          z-index="4"
          width="100%"
          :max-width="['200px', '320px']"
          border-radius="8px"
          background-color="#DFDFDF"
          color="neutral.black"
        >
          <c-popover-arrow />
          <c-popover-body
            :padding="['8px', '12px']"
            :font-size="['10px', '14px']"
          >
            {{ popoverText }}
          </c-popover-body>
        </c-popover-content>
      </c-popover>
    </c-flex>
    <c-text
      margin-top="5px"
      :font-size="['16px', '18px']"
      font-weight="500"
      color="neutral.black"
    >
      {{ type === 'adult' ? `${imtValue}kg/m2` : imtValue }}
    </c-text>
    <c-text
      margin-top="5px"
      :font-size="['12px', '14px']"
      font-weight="400"
      color="neutral.darkGray"
    >
      Status gizi mu: {{ imtStatus }}
    </c-text>
    <c-box margin-top="16px">
      <IndicatorStatusGizi
        :value="valueIndicator"
        :type="type"
      />
    </c-box>
  </c-flex>
</template>

<script>
import IndicatorStatusGizi
  from '@/components/meal-plan/_widgets/indicator-status-gizi.vue'

export default {
  name: 'CardInfoKondisiGiziku',
  components: { IndicatorStatusGizi },
  props: {
    type: {
      type: String,
      default: 'adult',
    },
    valueIndicator: {
      type: Number,
      default: 0,
    },
    imtValue: {
      type: [Number, String],
      default: 0,
    },
    imtStatus: {
      type: String,
      default: '-',
    },
    popoverText: {
      type: String,
      default: '',
    },
  },
}
</script>
