import { render, staticRenderFns } from "./indicator-status-gizi.vue?vue&type=template&id=34581d62&"
import script from "./indicator-status-gizi.vue?vue&type=script&lang=js&"
export * from "./indicator-status-gizi.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex})
