var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "flex-direction": "column",
      "border": "1px solid #C4C4C4",
      "padding": ['12px 16px', '16px'],
      "border-radius": "8px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "14px",
      "color": "neutral.black"
    }
  }, [_vm._v(" " + _vm._s(_vm.type === 'adult' ? 'Indeks Masa Tubuh' : 'Z-Score Status Gizi') + " ")]), _vm.popoverText !== '' ? _c('c-popover', {
    attrs: {
      "use-portal": false,
      "trigger": "click",
      "placement": "bottom"
    }
  }, [_c('c-popover-trigger', [_c('c-box', {
    attrs: {
      "min-width": "20px",
      "min-height": "20px",
      "width": "20px",
      "height": "20px",
      "cursor": "pointer"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-info.svg'),
      "stroke": "#555555",
      "width": "100%",
      "height": "100%"
    }
  })], 1)], 1), _c('c-popover-content', {
    attrs: {
      "border": "0",
      "z-index": "4",
      "width": "100%",
      "max-width": ['200px', '320px'],
      "border-radius": "8px",
      "background-color": "#DFDFDF",
      "color": "neutral.black"
    }
  }, [_c('c-popover-arrow'), _c('c-popover-body', {
    attrs: {
      "padding": ['8px', '12px'],
      "font-size": ['10px', '14px']
    }
  }, [_vm._v(" " + _vm._s(_vm.popoverText) + " ")])], 1)], 1) : _vm._e()], 1), _c('c-text', {
    attrs: {
      "margin-top": "5px",
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "color": "neutral.black"
    }
  }, [_vm._v(" " + _vm._s(_vm.type === 'adult' ? "".concat(_vm.imtValue, "kg/m2") : _vm.imtValue) + " ")]), _c('c-text', {
    attrs: {
      "margin-top": "5px",
      "font-size": ['12px', '14px'],
      "font-weight": "400",
      "color": "neutral.darkGray"
    }
  }, [_vm._v(" Status gizi mu: " + _vm._s(_vm.imtStatus) + " ")]), _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('IndicatorStatusGizi', {
    attrs: {
      "value": _vm.valueIndicator,
      "type": _vm.type
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }