<template>
  <c-flex
    flex-direction="column"
    border="1px solid #C4C4C4"
    :padding="['12px 16px', '16px']"
    border-radius="8px"
    justify-content="center"
    :align-items="['flex-start', 'center']"
  >
    <c-text
      font-size="14px"
      color="neutral.4F4F4F"
    >
      {{ title }}
    </c-text>
    <c-text
      font-size="16px"
      font-weight="500"
    >
      {{ value }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: 'CardInfoOutline',
  props: {
    title: {
      type: String,
      default: '-',
    },
    value: {
      type: String,
      default: '-',
    },
  },
}
</script>
