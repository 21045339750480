var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "border": "1px solid #C4C4C4",
      "padding": ['12px 16px', '16px'],
      "border-radius": "8px",
      "justify-content": "center",
      "align-items": ['flex-start', 'center']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "14px",
      "color": "neutral.4F4F4F"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }