var render = function () {
  var _vm$note;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "border": "1px solid #F2F2F2",
      "padding": "10px 12px",
      "border-radius": "12px",
      "gap": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "4px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "color": "neutral.darkGray",
      "font-size": ['14px', '16px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "90px",
      "border-right": "1px solid #C4C4C4"
    }
  }, [_vm._v(" Sekarang ")]), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "align-items": "center",
      "gap": "6px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "calc(".concat(_vm.currentBarWidth, " - ").concat(_vm.gapWidth, "px - ").concat(_vm.defaultCaloryWidth, "px)"),
      "min-width": "16px",
      "height": ['16px', '20px'],
      "border-top-right-radius": ['4px', '6px'],
      "border-bottom-right-radius": ['4px', '6px'],
      "background-color": "#F4CC46",
      "transition-duration": "500ms"
    }
  }), _c('c-box', {
    attrs: {
      "id": "".concat(_vm.title, "CurrentCalory"),
      "flex-shrink": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentParse) + " " + _vm._s(_vm.unit) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "color": "neutral.darkGray",
      "font-size": ['14px', '16px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "90px",
      "border-right": "1px solid #C4C4C4"
    }
  }, [_vm._v(" Kebutuhan ")]), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "align-items": "center",
      "gap": "6px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "calc(".concat(_vm.requirementBarWidth, " - ").concat(_vm.gapWidth, "px - ").concat(_vm.defaultCaloryWidth, "px)"),
      "min-width": "16px",
      "height": ['16px', '20px'],
      "border-top-right-radius": ['4px', '6px'],
      "border-bottom-right-radius": ['4px', '6px'],
      "background-color": "#00A68C",
      "transition-duration": "500ms"
    }
  }), _c('c-box', {
    attrs: {
      "id": "".concat(_vm.title, "RequirementCalory"),
      "flex-shrink": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.requirementParse) + " " + _vm._s(_vm.unit) + " ")])], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "gap": "6px",
      "padding": "8px",
      "background-color": "warning.50",
      "border-radius": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "flex-shrink": "0",
      "width": ['14px', '18px'],
      "height": "auto"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-warning.svg'),
      "width": "100%",
      "fill": "#DA6D06"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "display": "flex-inline",
      "flex-grow": "1",
      "font-size": ['10px', '14px'],
      "color": "neutral.superDarkGray"
    }
  }, [_vm.status ? _c('c-text', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" " + _vm._s(_vm.status) + ". ")]) : _vm._e(), _c('c-text', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" " + _vm._s((_vm$note = _vm.note) !== null && _vm$note !== void 0 ? _vm$note : '-') + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }