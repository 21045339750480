<template>
  <c-flex
    flex-direction="column"
    width="100%"
    min-height="30px"
    align-items="center"
  >
    <c-box
      :background="indicatorColor"
      border-radius="7.5px"
      width="100%"
      :height="['15px', '20px']"
      position="relative"
    >
      <c-box
        position="absolute"
        :height="['18px', '30px']"
        :width="['18px', '30px']"
        border-radius="50%"
        box-shadow="0px 0px 5px 0px rgba(0, 0, 0, 0.25)"
        background-color="white"
        top="50%"
        :left="value+'%'"
        :margin="adjustPosition"
        transform="translate(-50%, -50%)"
      />
    </c-box>
    <c-flex
      margin-top="10px"
      width="100%"
      justify-content="space-between"
    >
      <c-text
        :font-size="['12px', '14px']"
        color="neutral.superDarkGray"
        width="100%"
      >
        {{ indicatorText[0] }}
      </c-text>
      <c-text
        :font-size="['12px', '14px']"
        color="neutral.superDarkGray"
        width="100%"
        text-align="center"
      >
        {{ indicatorText[1] }}
      </c-text>
      <c-text
        :font-size="['12px', '14px']"
        color="neutral.superDarkGray"
        width="100%"
        text-align="right"
      >
        {{ indicatorText[2] }}
      </c-text>
    </c-flex>
  </c-flex>
</template>

<script>
export default {
  name: 'IndicatorStatusGizi',
  props: {
    type: {
      type: String,
      default: 'adult',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    adjustPosition() {
      if (this.value <= 0) {
        return '0 10px'
      } else if (this.value >= 100) {
        return '0 -10px'
      }
      return '0 0'
    },
    indicatorText() {
      switch (this.type) {
        case 'adult':
          return ['Kurang Gizi', 'Normal', 'Obesitas']
        default:
          return ['Berat Badan Kurang', 'Normal', 'Obesitas']
      }
    },
    indicatorColor() {
      switch (this.type) {
        case 'adult':
          return 'linear-gradient(90deg, hsla(54, 100%, 50%, 1) 0%, hsla(123, 52%, 43%, 1) 50%, hsla(0, 100%, 50%, 1) 100%);'
        default:
          return 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,230,0,1) 25%, rgba(53,168,58,1) 50%, rgba(255,230,0,1) 75%, rgba(255,0,0,1) 100%);'
      }
    },
  },
}
</script>
