<template>
  <c-flex
    flex-direction="column"
    width="100%"
    border="1px solid #F2F2F2"
    padding="10px 12px"
    border-radius="12px"
    gap="8px"
  >
    <c-text
      :font-size="['16px', '18px']"
      font-weight="500"
    >
      {{ title }}
    </c-text>
    <c-flex
      flex-direction="column"
      gap="4px"
    >
      <c-flex
        align-items="center"
        gap="8px"
        color="neutral.darkGray"
        :font-size="['14px', '16px']"
      >
        <c-box
          width="90px"
          border-right="1px solid #C4C4C4"
        >
          Sekarang
        </c-box>
        <c-flex
          flex-grow="1"
          align-items="center"
          gap="6px"
        >
          <c-box
            :width="`calc(${currentBarWidth} - ${gapWidth}px - ${defaultCaloryWidth}px)`"
            min-width="16px"
            :height="['16px', '20px']"
            :border-top-right-radius="['4px', '6px']"
            :border-bottom-right-radius="['4px', '6px']"
            background-color="#F4CC46"
            transition-duration="500ms"
          />
          <c-box
            :id="`${title}CurrentCalory`"
            flex-shrink="0"
          >
            {{ currentParse }} {{ unit }}
          </c-box>
        </c-flex>
      </c-flex>
      <c-flex
        align-items="center"
        gap="8px"
        color="neutral.darkGray"
        :font-size="['14px', '16px']"
      >
        <c-box
          width="90px"
          border-right="1px solid #C4C4C4"
        >
          Kebutuhan
        </c-box>
        <c-flex
          flex-grow="1"
          align-items="center"
          gap="6px"
        >
          <c-box
            :width="`calc(${requirementBarWidth} - ${gapWidth}px - ${defaultCaloryWidth}px)`"
            min-width="16px"
            :height="['16px', '20px']"
            :border-top-right-radius="['4px', '6px']"
            :border-bottom-right-radius="['4px', '6px']"
            background-color="#00A68C"
            transition-duration="500ms"
          />
          <c-box
            :id="`${title}RequirementCalory`"
            flex-shrink="0"
          >
            {{ requirementParse }} {{ unit }}
          </c-box>
        </c-flex>
      </c-flex>
    </c-flex>
    <c-flex
      flex-direction="row"
      gap="6px"
      padding="8px"
      background-color="warning.50"
      border-radius="8px"
    >
      <c-box
        flex-shrink="0"
        :width="['14px', '18px']"
        height="auto"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-warning.svg')"
          width="100%"
          fill="#DA6D06"
        />
      </c-box>
      <c-flex
        flex-direction="row"
        display="flex-inline"
        flex-grow="1"
        :font-size="['10px', '14px']"
        color="neutral.superDarkGray"
      >
        <c-text
          v-if="status"
          as="span"
        >
          {{ status }}.
        </c-text>
        <c-text
          as="span"
        >
          {{ note ?? '-' }}
        </c-text>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>

export default {
  name: 'CardInfoAsupanGizi',
  props: {
    title: {
      type: String,
      default: 'Energi',
    },
    current: {
      type: Number,
      default: 0,
    },
    requirement: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '-',
    },
    unit: {
      type: String,
      default: 'Kalori',
    },
  },
  data() {
    return {
      currentCaloryWidth: 100,
      requirementCaloryWidth: 100,
      defaultCaloryWidth: 100,
      gapWidth: 30,
    }
  },
  computed: {
    currentParse() {
      if (this.current) return Number(this.current.toFixed(0))
      return 0
    },
    requirementParse() {
      if (this.requirement) return Number(this.requirement.toFixed(0))
      return 0
    },
    currentBarWidth() {
      if (this.currentParse < 1) return '5%'
      if (this.requirementParse < this.currentParse) return '100%'
      return `${((this.currentParse / this.requirementParse) * 100).toFixed(0)}%`
    },
    requirementBarWidth() {
      if (this.requirementParse < 1) return '5%'
      if (this.requirementParse > this.currentParse) return '100%'
      return `${((this.requirementParse / this.currentParse) * 100).toFixed(0)}%`
    },
  },
  mounted() {
    const currentCalory = document.getElementById(`${this.title}CurrentCalory`)
    this.currentCaloryWidth = currentCalory?.offsetWidth ?? 0

    const requirementCalory = document.getElementById(`${this.title}RequirementCalory`)
    this.requirementCaloryWidth = requirementCalory?.offsetWidth ?? 0

    if (this.currentCaloryWidth > this.requirementCaloryWidth) {
      this.defaultCaloryWidth = this.currentCaloryWidth 
    } else {
      this.defaultCaloryWidth = this.requirementCaloryWidth
    }
  },
}
</script>
