<template>
  <c-flex
    width="100%"
    flex-direction="column"
    background-color="#007067"
    :padding="['12px 16px', '16px']"
    border-radius="8px"
    color="white"
    justify-content="center"
    :align-items="['flex-start', 'center']"
  >
    <c-text
      font-size="16px"
    >
      {{ title }}
    </c-text>
    <c-text
      font-size="18px"
      font-weight="500"
    >
      {{ value }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: 'CardInfoOutlineHighlight',
  props: {
    title: {
      type: String,
      default: '-',
    },
    value: {
      type: String,
      default: '-',
    },
  },
}
</script>
