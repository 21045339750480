var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "min-height": "30px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "background": _vm.indicatorColor,
      "border-radius": "7.5px",
      "width": "100%",
      "height": ['15px', '20px'],
      "position": "relative"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "absolute",
      "height": ['18px', '30px'],
      "width": ['18px', '30px'],
      "border-radius": "50%",
      "box-shadow": "0px 0px 5px 0px rgba(0, 0, 0, 0.25)",
      "background-color": "white",
      "top": "50%",
      "left": _vm.value + '%',
      "margin": _vm.adjustPosition,
      "transform": "translate(-50%, -50%)"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": "10px",
      "width": "100%",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '14px'],
      "color": "neutral.superDarkGray",
      "width": "100%"
    }
  }, [_vm._v(" " + _vm._s(_vm.indicatorText[0]) + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['12px', '14px'],
      "color": "neutral.superDarkGray",
      "width": "100%",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.indicatorText[1]) + " ")]), _c('c-text', {
    attrs: {
      "font-size": ['12px', '14px'],
      "color": "neutral.superDarkGray",
      "width": "100%",
      "text-align": "right"
    }
  }, [_vm._v(" " + _vm._s(_vm.indicatorText[2]) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }